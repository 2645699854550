import React, { Component } from "react";

function DeleteAccount() {
  return (
    <>
      <h2>Delete Account</h2>
      <p>
        <strong>Current Account Deletion Process</strong>
      </p>
      <p>
        We understand your right to data privacy and are committed to honoring
        your request to close your account. While we're actively working on a
        self-service option for your convenience, currently, account closure
        requires contacting us directly.
      </p>
      <p>
        In the meantime, you can easily request account closure by sending an
        email to [munazemteam@munazem.com]. We'll promptly process your request
        and securely delete your information upon confirmation.
      </p>
      <p>
        <strong>Here's what to include in your email:</strong>
      </p>
      <p>Your full name</p>
      <p>Your registered email address</p>
      <p>
        <strong>
          We appreciate your patience as we improve our account management
          features!
        </strong>
      </p>
    </>
  );
}
export default DeleteAccount;
