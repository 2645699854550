import React from "react";
import "./DiscoverOurGroupsBlock.css";
import GroupsCarousel from "./PageComponents/GroupsCarousel";

function DiscoverOurGroupsBlock() {
  return (
    <div className="DiscoverOurGroupsBlock_MainContainer">
      <h3 id="DiscoverOurGroupsBlock_h3">Discover some of our groups</h3>
      <p id="DiscoverOurGroupsBlock_p">
        At Munazem we have a variety of groups to suit everyone
      </p>
      <GroupsCarousel />
    </div>
  );
}

export default DiscoverOurGroupsBlock;
