import React, { useState } from "react";
import { useSelector } from "react-redux";
import EventBox from "../components/EventBox";
import EventBox1 from "../components/EventBox1";
import EventBox2 from "../components/EventBox2";
import EventBox3 from "../components/EventBox3";
import EventBox4 from "../components/EventBox4";
import EventBox5 from "../components/EventBox5";
import EventBox6 from "../components/EventBox6";
import "./Calendar.scss";

function Calendar() {
  const user = useSelector((state) => state.user);

  console.log(user.name);
  if (!user) {
    return <></>;
  }

  return (
    <div className="my-body">
      <h1>Gatherings for this month</h1>
      <ul>
        {/* <button onClick={handleJoin}>Join</button>
      <button onClick={handleWithdraw}>With Draw</button> */}
        {/* <EventBox /> */}
        <li>
          <EventBox1 />
        </li>
        <li>
          <EventBox2 />
        </li>
        <li>
          <EventBox3 />
        </li>
        <li>
          <EventBox4 />
        </li>
        <li>
          <EventBox5 />
        </li>
        <li>
          <EventBox6 />
        </li>
      </ul>
    </div>
  );
}

export default Calendar;
