import React, { Component } from "react";

function PrivacyPolicyPage() {
  return (
    <>
      <h1>Privacy Policy</h1>
      <div className="Introduction of the Privacy Policy">
        <p>
          Munazem built the Munazem's Website and App as a free Website and free
          App. Those SERVICES are provided by Munazem at no cost and is intended
          for use as is.
        </p>
        <p>
          This page is used to inform website visitors regarding our policies
          with the collection, use, and disclosure of Personal Information if
          anyone decided to use our Website or App.
        </p>
        <p>
          If you choose to use our Website or App, then you agree to the
          collection and use of information in relation with this policy. The
          Personal Information that we collect are used for providing and
          improving the Service. We will not use or share your information with
          anyone except as described in this Privacy Policy.
        </p>
        <p>
          This Privacy Policy applies to both our Website and Mobile Application
          (collectively referred to as "the Service").
        </p>
        <div className="Log Data">
          <p>
            <strong>Log Data</strong>
          </p>
          <p>
            We want to inform you that whenever you use our Service, in case of
            an error in the app We may collect data and information (through
            third party products) on your phone called Log Data. This Log Data
            may include information such as your devices’s Internet Protocol
            (“IP”) address, device name, operating system version, configuration
            of the app when utilising our Service, the time and date of your use
            of the Service, and other statistics.
          </p>
        </div>
        <div className="Information Collection Section">
          <p>
            <strong>Information Collection and Use</strong>
          </p>
          <p>
            For a better experience while using our Service, We may require you
            to provide us with certain personally identifiable information,
            including but not limited to [users name | address | location |
            pictures]. The information that We request will be retained by us
            and used as described in this privacy policy.
          </p>
        </div>
        <div className="Cookies">
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            Cookies are files with small amount of data that is commonly used an
            anonymous unique identifier. These are sent to your browser from the
            website that you visit and are stored on your devices’s internal
            memory.
          </p>
          <p>
            The Website and the App do not uses these “cookies” explicitly.
            However, the Website and App may use third party code and libraries
            that use “cookies” to collection information and to improve their
            services. You have the option to either accept or refuse these
            cookies, and know when a cookie is being sent to your device. If you
            choose to refuse our cookies, you may not be able to use some
            portions of the App.
          </p>
        </div>
        <div className="Service providers part">
          <p>
            <strong>Service Providers</strong>
          </p>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>

          <p>To facilitate our Service;</p>
          <p>To provide the Service on our behalf;</p>
          <p>To perform Service-related services; or</p>
          <p>To assist us in analyzing how our Service is used.</p>

          <p>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
        </div>
        <div className="Security Part">
          <p>
            <strong>Security</strong>
          </p>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and We
            cannot guarantee its absolute security.
          </p>
        </div>
        <div className="Other Sites Part">
          <p>
            <strong>Links to Other Sites</strong>
          </p>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, We strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
        </div>
        <div className="Childern's Policy part">
          <p>
            <strong>Children’s Privacy</strong>
          </p>
          <p>
            This Services do not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case We discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </p>
        </div>
        <div className="Changes to Policy Part">
          <p>
            <strong>Changes to This Privacy Policy</strong>
          </p>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately, after they are posted
            on this page.
          </p>
        </div>
        <div className="Contact Us part">
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at munazemteam@munazem.com .
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
