import React, { useState } from "react";
import "./ForgotPassword.css";

function ForgotPassword() {
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://munazem-api.onrender.com/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const alertMessage = () => {
    alert("An email will be sent to the provided email shortly");
  };
  return (
    <div className="form-wrapper">
      <form onSubmit={handleSubmit}>
        <label>
          <p>Email:</p>
          <div className="form-item">
            <input
              type="email"
              name="email"
              placeholder="Email"
              // className="form-item"
              onChange={handleChange}
            />
          </div>
        </label>
        <br />
        <div class="button-panel">
          <button type="submit" className="button" onClick={alertMessage}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
