import React from "react";
import { LinkContainer } from "react-router-bootstrap";
// import { Row, Col, Button } from "react-bootstrap";
import "./DiscoverNowButton.css";

function DiscoverNowButton() {
  return (
    <div>
      <LinkContainer to="/chat">
        <button className="DiscoverNowbtn">Discover now</button>
      </LinkContainer>
    </div>
  );
}

export default DiscoverNowButton;
