import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
// import TypewriterComponent from "typewriter-effect";
// import mapLine from "../assets/map_line.png";
import blueShape from "../assets/shape_6.png";
import peopleInPic from "../assets/why_choose_1.jpg";
// import App from "./testingPage";
import HomeSlideinText1 from "../components/HomeSlideInText1";
import HomeSlideinText2 from "../components/HomeSlideInText2";
import JoinMunazem from "../components/HomePageComponents/JoinMunazemBlock";
// import ConnectMeetAndMore from "../components/HomePageComponents/ConnectMeet&MoreBlock";
import WhoWeAreBlock from "../components/HomePageComponents/WhoWeAreBlock";
import JoinOurFastGrowingNetworkBlock from "../components/HomePageComponents/JoinOurFastGrowingNetworkBlock";
import DiscoverOurGroupsBlock from "../components/HomePageComponents/DiscoverOurGroupsBlock";

import "./Home.css";

function Home() {
  return (
    <div>
      {/* <ConnectMeetAndMore /> */}
      <JoinOurFastGrowingNetworkBlock />
      <WhoWeAreBlock />

      {/* <div className="WeMakeConnectingEasier_Block">
        <h3 id="h3In_WeMackCEBlock">
          Munazem Makes Connecting Simpler & Easier{" "}
        </h3>
        <p id="pIn_WeMackCEBlock">
          Munazem is a social media platfrom, dedicated to connect people
          through various events and activites. We belive that the
        </p>
        <img
          id="imgIn_WeMackCEBlock"
          src={blueShape}
          alt="My Image"
           style={{ position: "absolute", top: 0, left: 0 }}
        />
        <img id="pplPic_WeMackCEBlock" src={peopleInPic} />
      </div> */}
      {/* <div className="whyJoinMunazem_Block" style={{ padding: "60px 60px" }}>
        <p style={{ color: "#615dfa" }}>What we do</p>
        <h3 className="whyJoinMunazem_Block_h3">Why Join Munazem?</h3>
        <p>
          We are still gonna write this <br />
          through various events and activites. We belive that the
        </p> */}
      {/* <App /> */}
      {/* <HomeSlideinText1 /> */}
      <DiscoverOurGroupsBlock />
      <HomeSlideinText2 />
      <div>
        <JoinMunazem />
      </div>
      {/* </div> */}
    </div>
  );
}

export default Home;
