import React, { useState } from "react";

function AdminPageForm() {
  const [name, setName] = useState("");
  const [NumberOfPeople, setNumber] = useState("");
  const [PeopleJoined, setPeopleJoined] = useState("");
  const [eventBoxName, seteventBoxName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [eventDay, setEventDay] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Hello ${name}!`);
    fetch("https://munazem-api.onrender.com/tournament-info", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        // name: "User1",
        name,
        NumberOfPeople,
        PeopleJoined,
        eventName,
        eventDetails,
        eventDay,
      }),
    }).then((response) => {
      //do something awesome that makes the world a better place
    });
  };

  const handleDeleteDBInfo = (event) => {
    event.preventDefault();
    // alert(`Hello ${name}!`);
    fetch("https://munazem-api.onrender.com/tournament-info", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };

  const handleDeleteEventBox = (event) => {
    event.preventDefault();
    // alert(`Hello ${name}!`);
    fetch(`https://munazem-api.onrender.com/tournament-info/${eventBoxName}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label>
        Number of people to join:
        <input
          type="number"
          value={NumberOfPeople}
          onChange={(event) => setNumber(event.target.value)}
        />
      </label>
      <label>
        Number of people joined:
        <input
          type="number"
          value={PeopleJoined}
          onChange={(event) => setPeopleJoined(event.target.value)}
        />
      </label>
      <label>
        Event Name:
        <input
          type="text"
          value={eventName}
          onChange={(event) => setEventName(event.target.value)}
        />
      </label>
      <label>
        Event Details:
        <input
          type="text"
          value={eventDetails}
          onChange={(event) => setEventDetails(event.target.value)}
        />
      </label>
      <label>
        Event Day:
        <input
          type="text"
          value={eventDay}
          onChange={(event) => setEventDay(event.target.value)}
        />
      </label>
      <input type="submit" value="Submit" />
      <button onClick={handleDeleteDBInfo}>
        Deleting information of the event
      </button>
      <label>
        Delete Event Box
        <input
          type="text"
          value={eventBoxName}
          onChange={(event) => seteventBoxName(event.target.value)}
        />
      </label>
      <button onClick={handleDeleteEventBox}>Delet the Event box</button>
    </form>
  );
}

export default AdminPageForm;
