// Code before chagnes

import React, { Component, useState, useEffect, useContext } from "react";
import { NavItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppContext } from "../context/appContext";
import "./EventBox.css";
//import "./MessageForm.css";

function EventBox() {
  const user = useSelector((state) => state.user);
  const { socket } = useContext(AppContext);
  let clientName = null;
  let [name, setName] = useState("");
  let [test, setTest] = useState([]);
  const [clickCount, setClickCount] = useState(0); //Newely added
  const [nameCheckerFromDB, setnameCheckerFromDB] = useState(""); //Newely added

  const [removeName, setRemoveName] = useState("");
  const [data, setData] = useState([]);
  const [numberOfDB, setNumberOfDB] = useState([]);
  let [emittedData, setEmittedData] = useState("");

  socket.on("chat-message2", (emittedData) => {
    // console.log("chat-message2 Fola Emitted Data" + emittedData);
    setEmittedData(emittedData);
  });
  socket.on("counting-users2", (emittedData) => {
    // console.log("counting-users2 Fola Emitted Data" + emittedData);
    setEmittedData(emittedData);
  });

  useEffect(() => {
    fetch("https://munazem-api.onrender.com/tournament-info/EventBox2", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data, "userData");
        setData(data.data);
      });
    setName(user.name);
    fetch(`https://munazem-api.onrender.com/eventbox2/${name}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data[0].name);
        setTest(data.data[0].name);
        // console.log(test + "Test Variable");
        // console.log("The name is. tese fes kola " + data.data[0].name); //New trial
        const nameComingFromDB = data.data[0].name; //New trial
        setnameCheckerFromDB(nameComingFromDB);
        //setData(data.data);
      });
    // console.log(data.data);
    async function fetchName() {
      setName(user.name);
      const url = `https://munazem-api.onrender.com/eventbox2/${name}`;
      const response = await fetch(url, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        // console.log("The name is. tese fes kola " + data.data[0].name); //New trial
        const nameComingFromDB = data.data[0].name; //New trial
        setnameCheckerFromDB(nameComingFromDB);
      }
    }
    return [fetchName];
  }, []); //what was in the array name, removeName, data

  useEffect(() => {
    fetch(`https://munazem-api.onrender.com/eventbox2/${name}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data[0].name);
        setTest(data.data[0].name);
        // console.log(test + "Test Variable");
        // console.log("The name is. tese fes kola " + data.data[0].name); //New trial
        const nameComingFromDB = data.data[0].name; //New trial
        setnameCheckerFromDB(nameComingFromDB);
      });
  });

  const handleNewJoin = (e) => {
    e.preventDefault();
    setName(user.name);

    async function sendingInfromation() {
      const url = `https://munazem-api.onrender.com/eventbox2`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: user.name,
          email: user.email,
        }),
      });
      socket.emit("counting-users2");
      socket.emit("chat-message2");
    }
    sendingInfromation();
    async function fetchName() {
      const url = `https://munazem-api.onrender.com/eventbox2/${name}`;
      const response = await fetch(url, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        // console.log("The name is. tese fes kola " + data.data[0].name); //New trial
        const nameComingFromDB = data.data[0].name; //New trial
        setnameCheckerFromDB(nameComingFromDB);
      }
    }
    fetchName();
  };
  const handleLeave = (e) => {
    //socket.emit("chat-message");
    e.preventDefault();
    setRemoveName(user.name);
    setName(user.name);
    async function deletehName() {
      const url = `https://munazem-api.onrender.com/eventbox2`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          //name: "User1",
          name: user.name,
        }),
      });
      socket.emit("counting-users2");
      socket.emit("chat-message2");
    }
    deletehName();

    async function fetchName() {
      const url = `https://munazem-api.onrender.com/eventbox2/${name}`;
      const response = await fetch(url, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        const newData = Array.from(data);
        // console.log(newData);
        setnameCheckerFromDB(data.data);
        // console.log(data.data.map((item) => item.name));
        setnameCheckerFromDB(data.data.map((item) => item.name));
        // console.log("Again Name is" + nameCheckerFromDB);
      }
    }
    fetchName();
  };
  async function fetchName() {
    setName(user.name);
    const url = `https://munazem-api.onrender.com/eventbox2/${name}`;
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.ok) {
      const data = await response.json();
      // console.log(data);
      // console.log("The name is. tese fes kola " + data.data[0].name); //New trial
      const nameComingFromDB = data.data[0].name; //New trial
      setnameCheckerFromDB(nameComingFromDB);
    }
  }

  return (
    <>
      {/* <p1>Events Should be here</p1>
    <p1>{data.map((item) => item.name)}</p1> */}
      <p>{data.map((item) => item.eventDay)}</p>
      <div>
        {data.map((item) => (
          <div key={item.name}>
            <h1>{item.eventName}</h1>
            {/* <p>{item.name}</p> */}
            <p>
              People Joining {emittedData}/{item.NumberOfPeople}
            </p>
            <button
              onClick={handleNewJoin}
              disabled={
                emittedData >= item.NumberOfPeople ||
                nameCheckerFromDB == user.name
              }
            >
              Join
            </button>
            <button onClick={handleLeave}>Leave</button>
            <p>{item.eventDetails}</p>
          </div>
        ))}
      </div>
      {/* <div>
      {numberOfDB.map((item) => (
        <div key={item.data}>
          <h1> Hello Word{item.data}</h1>
          <p>This is the Events for {data.map((item) => item.name)}</p>
        </div>
      ))}
    </div> */}
    </>
  );
}

export default EventBox;
