import React from "react";
import JoinOurFastGrowingNetworkBlockImg from "../../assets/JoinOurFastGrowingNetwork.png";
import DiscoverNowButton from "./PageComponents/DiscoverNowButton";
import "./JoinOurFastGrowingNetworkBlock.css";

function JoinOurFastGrowingNetworkBlock() {
  return (
    <div className="JoinOurFastGrowingNetworkBlock_MainContainer">
      <h3 id="JoinOurFastGrowingNetworkBlock_h3">
        Join our fast growing network now!
      </h3>
      <p id="JoinOurFastGrowingNetworkBlock_p">
        Welcome to Munazem. A social media platform designed to make it easy to
        stay in touch with everyone and meet new people
      </p>
      <img
        id="JoinOurFastGrowingNetworkBlock_img"
        src={JoinOurFastGrowingNetworkBlockImg}
      />
      <DiscoverNowButton />
    </div>
  );
}

export default JoinOurFastGrowingNetworkBlock;
