import React from "react";
import { Link, useNavigate } from "react-router-dom";
import NewsletterFrom from "./PageComponents/NewsletterFrom";
import "./JoinMunazemBlock.css";

function JoinMunazem() {
  return (
    <div className="mainContainer">
      <div className="text">
        <h1>Subscribe to our Newsletter </h1>
        <p>
          Join Munazem to get updates on our new <br />
          features, events, gatherings, and much more
        </p>
        <NewsletterFrom />
        {/* <Link to="/signup">Signup</Link> */}
      </div>
      <div className="Privacy Policy Sentece">
        Learn more about how we handle your data in our
        <Link
          to="/privacy"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          Privacy Policy
        </Link>
        Page
      </div>
    </div>
  );
}

export default JoinMunazem;
