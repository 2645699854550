import React from "react";
import "./RollingText.css";

function RollingText() {
  return (
    <div>
      <div id="RollingTextContainer">
        {/* Make <h1>Munazem is a dedicated socail media platfrom</h1> */}
        <div id="RollingTextFlip">
          <div>
            <div>Events</div>
          </div>
          <div>
            <div>Activities</div>
          </div>
          <div>
            <div>Gatherings</div>
          </div>
        </div>
        {/* AweSoMe! */}
      </div>
    </div>
  );
}

export default RollingText;
