import React from "react";
// import RollingText from "../components/HomePageComponents/PageComponents/RollingText";
import RollingText from "../HomePageComponents/PageComponents/RollingText";
import WeConnectPeopleImg from "../../assets/WeConnectPeopleImg.jpg";
import FindTheRightGroupImg from "../../assets/FindTheRightGroupImg.jpg";
import "./WhoWeAreBlock.css";

function WhoWeAreBlock() {
  return (
    <>
      <div className="whoWeAre_Block">
        <p id="WhoWeAre_BlueTitle">Who we are?</p>
        <h3
          id="whoWeAre_Blockh3"

          // style={{ position: "relative", left: "270px" }}
        >
          We help connecting people <br />
          through various:
          <br />{" "}
        </h3>

        {/* <h3>
          through various: <br />
        </h3> */}
        <RollingText />
        <p className="WhoWeAre_P">
          Munazem is a dedicated social media platform that helps people connect
          through various events. We believe in the power of physical
          communication
        </p>
        <img id="WeConnectPeopleImg" src={WeConnectPeopleImg} />

        <h3 id="FindTheRightGroup_h3">
          Find the right group that fits your interests
        </h3>
        <p id="idFindTheRightGroup_p">
          {" "}
          At Munazem we make sure that to have a variety of groups to make sure
          that everyone has to place. We always create new groups to open the
          door for new adventures{" "}
        </p>
        <img id="idFindTheRightGroup_img" src={FindTheRightGroupImg} />
      </div>
    </>
  );
}

export default WhoWeAreBlock;
