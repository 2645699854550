import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AdminPageForm from "../components/AdminPageForm";

function AdminPage() {
  const user = useSelector((state) => state.user);

  console.log(user.name);
  if (!user) {
    return <></>;
  } else if (user.name !== "Admin") {
    return <></>;
  }

  return (
    <>
      {/* {!user && <div className="alert alert-danger">Please login</div>} */}
      <h1>Hello Admin</h1>
      <h4>Welcome</h4>
      <AdminPageForm />
    </>
  );
}

export default AdminPage;
