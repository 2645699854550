import React, { useState } from "react";
import "./NewsletterFrom.css";

function NewsletterForm() {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "") {
      alert("Enter email");
    } else {
      fetch("https://munazem-api.onrender.com/newsletter", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          // name: "User1",
          email,
        }),
      }).then(() => {
        alert(`Thank you for Subscribing`);
        //do something awesome that makes the world a better place
      });
      setEmail("");
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="newsletterInput"
          />
        </label>
      </form>
      <button className="newsletterSubmitButton" onClick={handleSubmit}>
        Subscribe now
      </button>
    </>
  );
}

export default NewsletterForm;
