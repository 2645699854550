import "./HomeSlideinText.css";

import { useState, useRef, useEffect } from "react";

function SlideinText2() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "-100px" }
    );
    console.log(isIntersecting);
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      ref.current.querySelectorAll("div").forEach((el) => {
        // el.classList.add("slide-in");
        el.classList.add("slide-in");
      });
    } else {
      ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.remove("slide-in");
      });
    }
  }, [isIntersecting]);

  return (
    <div>
      <div
        className="WhatWeDo_Block"
        style={{ position: "relative", top: "100px", left: "60px" }}
      >
        <p style={{ color: "#615dfa" }}>What we do</p>
        <h3
          style={{
            position: "relative",
          }}
        >
          Why Join Munazem?
        </h3>
        <p>
          Munazem offers a great way to meet new people, open new conversations,{" "}
          <br />
          and engage in thought-provoking discussions. Expand your social
          circle, and <br /> connect with others who share your interests,
          through our various events
        </p>
      </div>
      {/* <header>This is the Header</header> */}
      <main ref={ref}>
        <div className="child-two" style={{ position: "relative" }}>
          <h1
            style={{ position: "relative", right: "60px" }}
            id="makeWonderfulFriends"
          >
            Make Wonderful Friends{" "}
          </h1>
          <p
            style={{ position: "relative", bottom: "20px" }}
            id="makeWonderfulFriendsP"
          >
            Meet people who share the same passion
          </p>
          <h1 id="createConv">Create Conversations</h1>
          <p style={{ position: "relative", bottom: "20px" }} id="createConvP">
            Fourm discussions about your favorite topics
          </p>
          <h1
            style={{ position: "relative", right: "100px" }}
            id="EngagingGroups"
          >
            Engaging Groups
          </h1>
          <p
            style={{ position: "relative", bottom: "20px", right: "100px" }}
            id="EngagingGroupsP"
          >
            Active groups where you can share your thoughts
          </p>
        </div>
      </main>
      {/* <footer>This is the Footer</footer> */}
    </div>
  );
}

export default SlideinText2;
